import arcade from "./assets/arcade.png";
import arcadegame from "./assets/arcadegame.png";
import heroImage from "./assets/hero.png";
import profilePic from "./assets/profilePic.jpeg";

export default {
  arcade,
  arcadegame,
  heroImage,
  profilePic,
};
